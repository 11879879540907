* {
  margin: 0;
  padding: 0;
}

html, body {
  scroll-behavior: smooth;
}

img {
  pointer-events: none;
}

img::selection {
  background-color: transparent;
}

.animate-short {
  @apply transition-all duration-200 ease-in-out;
}

.animate-long {
  @apply transition-all duration-500 ease-in-out;
}